import React from "react"
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import { Grid, Margin, Picture } from '@components/atoms'
import { OutOfStockForm } from '@components/cart'
import { ShopH2 } from '@components/atoms/BlockRenderer'
import { TransitionMask } from '@components/organisms'
import { WithData, StoreContext, getCdnPath } from '@utils'
import { SiteSettings } from '@types'
import { palette } from '@theme'



interface ProductProps {
  pageContext: {
    settings: SiteSettings
  }
  data: {
    shopifyProduct: {
      title: string
      description?: string,
      descriptionHtml?: string
      media?: Array<{
        preview: {
          image?: {
            originalSrc: string
          }
        }
      }>
      handle: string
      variants: Array<{
        price: string
        storefrontId: string
        inventoryQuantity: number
        title: string
      }>
    }
  }
}

const ProductTemplate = ({ 
  pageContext: {
    settings,
  },
  data: {
    shopifyProduct: { 
      title, 
      description,
      descriptionHtml,
      media,
      variants,
    },
  },
}: ProductProps): React.ReactElement => {
  const projectSettings = {
    ...settings,
    subtitle: title,
    shareImage: media?.[0].preview.image?.originalSrc || settings.shareImage!.asset.url,
    keywords: settings.keywords,
    description: description || settings.description,
  }
  
  const parsedTitle = 'TWA: ' + title
  const meta = [
    { property: "og:title", content: parsedTitle },
    { name: "twitter:title", content: parsedTitle },
    { name: "description", content: description },
    { name: "twitter:description", content: description },
    { property: "og:description", content: description },
    { property: "og:image", content:  getCdnPath(`${projectSettings.shareImage}?w=640&fm=jpg`) },
    { name: "twitter:image", content:  getCdnPath(`${projectSettings.shareImage}?w=640&fm=jpg`) },
  ]

  const { addVariantToCart, setCartVisible } = React.useContext(StoreContext)
  
  const addAndShowCart = (variantID: string) => {
    addVariantToCart && addVariantToCart(variantID, 1)
    setCartVisible(true)
  }
  
  const [selectedVariant, setSelectedVariant] = React.useState<number>()
    
  const sizeLabel = (label: string): string => {
    switch( label.replace(/[^a-zA-Z\-]/g, "").toLowerCase() ) {
      case 'extrasmall': return 'xs'
      case 'small': return 's'
      case 'medium': return 'm'
      case 'large': return 'l'
      case 'extralarge': return 'xl'
      default: return '?'
    }
  }
    
  // todo: active button should account for quantity in cart < inventoryQuantity
    
  return (
    <>
      <Helmet {...{meta}} title={parsedTitle} >
        <title>{parsedTitle}</title>
      </Helmet>
      
      <Margin>
        <StyledGrid>
          
          <ProductDescriptionWrapper imageCount={media?.length || 1} >
            <ProductDescription>
              <ShopH2>{title}</ShopH2>
              <DescriptionCopy dangerouslySetInnerHTML={{ __html: descriptionHtml || "derp" }} />

              {variants.length == 1 && (
                <ButtonWrapper>
                  {variants[0].inventoryQuantity > 0 && (
                    <Button onClick={() => addAndShowCart(variants[0].storefrontId)} active={true}>
                      <span>Buy Now</span>
                      <span>${variants[0].price.toString().replace('.00', '')}</span>
                    </Button>              
                  )}
                  {variants[0].inventoryQuantity < 1 && (
                    <OutOfStockForm product={title} />
                  )}
                </ButtonWrapper>
              )}

              {variants.length > 1 && (
                <>
                  <Variants>
                    {variants.map((variant, i) => (
                      <Variant key={`variant_${i}`} soldOut={variant.inventoryQuantity < 1} selected={selectedVariant === i} onClick={() => setSelectedVariant(i)}>
                        {sizeLabel(variant.title)}
                      </Variant>
                    ))}
                  </Variants>
                  <ButtonWrapper>
                    {variants[selectedVariant || 0].inventoryQuantity > 0 && (
                      <Button onClick={() => addAndShowCart(variants[selectedVariant || 0].storefrontId)} active={!!selectedVariant} >
                        <span>Buy Now</span>
                        <span>${variants[selectedVariant || 0].price.toString().replace('.00', '')}</span>
                      </Button>              
                    )}
                    {variants[selectedVariant || 0].inventoryQuantity < 1 && (
                      <OutOfStockForm product={title} />
                    )}
                  </ButtonWrapper>
                </>
              )}

            </ProductDescription>
          </ProductDescriptionWrapper>
            
          {media && media.map((media, i) => !!media.preview.image && (
            <PictureWrapper key={`image_${i}`} hideMobile={i > 0} >
              <Picture src={media.preview.image?.originalSrc} />
            </PictureWrapper>
          ))}
          
        </StyledGrid>
      </Margin>

      <TransitionMask inverted={true} />
    </>
  )
}




const Variants = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 2em;
`


const Variant = styled.div<{
  soldOut: boolean,
  selected: boolean,
}>`
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border: 1px solid white;
  border-radius: 9999px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  font-weight: 700;
  line-height: 1em;
  color: ${props => props.selected ? 'black' : 'white'};
  background-color: ${props => props.selected ? 'white' : 'black'};
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  opacity: ${props => props.soldOut ? 0.3 : 1};
  pointer-events: ${props => props.soldOut ? 'none' : 'all'};
  cursor: pointer;
  text-transform: uppercase;
  &:hover {
    color: black;
    background-color: white;
  }
`


const StyledGrid = styled(props => <Grid {...props} />)`
  @media only screen and (max-width: 743px) {
    grid-template-rows: repeat(2, auto);
  }  
  @media only screen and (min-width: 744px) {
    width: auto !important;
    grid-row-gap: 0;
  }
`


const ProductDescriptionWrapper = styled.div<{ imageCount: number }>`
  @media only screen and (max-width: 743px) {
    grid-column: 1 / span 6;
    grid-row: 2;
    padding-top: 1em;
  }
  @media only screen and (min-width: 744px) {
    position: relative;
    grid-column: 3 / span 3;
    grid-row: 1 / span ${props => props.imageCount};
  }
`


const ProductDescription = styled.article`
  @media only screen and (min-width: 744px) {
    position: sticky;
    top: 12vw;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    margin-bottom: 0.75em;
  }
  ul {
    font-family: 'Founders', sans-serif;
    font-weight: 400;
    line-height: 1.2em;
    margin-bottom: 0.5em;
    margin-left: 20px;
    li {
      list-style-type: '·   ';
    }
  font-size: 12px;
  @media only screen and (min-width: 835px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 15px;
  }
  }
`


const DescriptionCopy = styled.div`
  color: white;
`

const ButtonWrapper = styled.div`
  margin-top: 2em;
`

const Button = styled.button<{
  active: boolean,
}>`
  -webkit-appearance: none;
  background-color: white;
  color: ${palette.thunder};
  font-family: 'Founders', sans-serif;
  font-weight: 500;
  line-height: 1.2em;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-bottom: 0.5em;
  font-size: 11px;
  @media only screen and (min-width: 835px) {
    font-size: 14px;
  }
  @media only screen and (min-width: 1600px) {
    font-size: 15px;
  }
  span {
    display: inline-block;
    padding: 10px 10px;
    &:first-of-type {
      padding: 10px 40px;
      border-right: 1px solid black;
    }
  }
  cursor: pointer;
  opacity: ${props => props.active ? 1 : 0.25};
  &:hover {
    opacity: 0.75;
  }
  transition: opacity 0.15s ease-in-out;
`


const PictureWrapper = styled.div<{
  hideMobile: boolean
}>`
  @media only screen and (max-width: 743px) {
    display: ${props => props.hideMobile ? 'none' : 'block'};
    grid-column: 1 / span 6;
    grid-row: 1;
    width: 100vw;
    margin-left: -20px;
  }
  @media only screen and (min-width: 744px) {
    grid-column-start: 7;
    grid-column-end: span 6;
  }
  min-height: 25vh;
  img {
    width: 100%;
    @media only screen and (min-width: 744px) {
      min-height: 700px;
      object-fit: cover;
    }
  }
`


const mapDataToProps = ({ shopifyProduct }: any) => ({ ...shopifyProduct })


export const query = graphql`
  query product($slug: String!) {
    shopifyProduct(handle: {eq: $slug}) {
      title
      description
      descriptionHtml
      media {
        preview {
          image {
            originalSrc
          }
        }
      }
      handle
      variants {
        price
        storefrontId
        inventoryQuantity
        title
      }
    }
  }
`

export default WithData(mapDataToProps, ProductTemplate)